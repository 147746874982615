import React from "react";
import { Accordion } from "react-bootstrap";
import "./freq.css";

function Freaquently() {
  return (
    <div>
      <h1 style={{ textAlign: "left", padding: "20px" }} id="yp">
        <b>Frequently Asked Questions</b>
      </h1>
      <p
        style={{ fontSize: "15px", marginTop: "-33px", textAlign: "left" }}
        className="p-4 container p11 "
      >
        <b>
          Uncover essential information and solutions in our Frequently Asked
          Questions (FAQ) section. Get quick and comprehensive responses to
          common queries, ensuring a seamless experience with our courier
          service.
        </b>
      </p>
      <Accordion
        className="container "
        style={{ marginTop: "2%" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">1. How can I find where my package is?</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                It's simple! Just type your tracking number on our website, and
                you'll see real-time updates about where your package is and
                when it will be delivered.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">
              2. When will my order arrive with standard shipping?
            </b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                Standard shipping typically takes 3-5 business days. Enter your
                order number on our website for real-time updates on your
                order's arrival.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">3. Can I get my order faster?</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                Currently, we do not offer expedited shipping options. However,
                we strive to process and ship all orders as quickly as possible.
                You can track your order on our website for the latest updates.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">4. Can I pay when I get my package?</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                To arrange for payment upon delivery, please contact the shipper
                directly. They will provide you with the available payment
                options and assist you in setting up your payment method.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">5. What if I'm not home when my package comes?</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                If you're not home, the courier will leave a notice with
                instructions on how to reschedule delivery or pick up your
                package from a nearby location.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b class="ft">6. Are there rules for what I can ship?</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                Yes, there are restrictions on certain items .Check the policies
                section
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">7. What if my package is damaged?</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                If your package is damaged, please contact our customer service
                immediately with your order number and photos of the damage. We
                will assist you with a replacement or refund.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">8. Are there extra charges I don't know about?</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                All applicable charges, including shipping and taxes, are
                displayed at checkout. There are no hidden fees.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">
              9. Can I change where my package gets delivered?
            </b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                Unfortunately, once an order has been placed, we are unable to
                change the delivery address. You can cancel the existing order
                and place a new one with the correct address. Please contact our
                customer service for assistance with canceling your order.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
      <Accordion
        className="container "
        style={{ marginTop: "-18px" }}
        defaultActiveKey="1"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b className="ft">10. How do I talk to someone for help?</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: "small" }}>
                For assistance, you can contact our customer service team via
                phone, email, or contact available on our website.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
      <br />
    </div>
  );
}

export default Freaquently;
