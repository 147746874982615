
import React from "react";

import AHeader from "./AHeader";
import Request from "./Request";
import Afooter from "./Afooter";

function Ahome() {
  
  return (
    <div>
      
      <AHeader   />
      
      <Request></Request>
      <Afooter></Afooter>
        </div>
  );
}

export default Ahome;
