import React from 'react'


function Pnf() {
  return (
    <div> 
      <img style={{marginRight:'100px',width:'100%'}} src="https://i.postimg.cc/C5ncVcX6/5203299.jpg" alt="ert" />
    </div>
  )
}

export default Pnf