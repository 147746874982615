import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Header from './Header';

function Rules() {
    const items = [
        {
            imgSrc: "https://i.postimg.cc/C5FB4Stj/currency.png",
            text: "Currency, Gold, Silver & Diamonds"
        },
        {
            imgSrc: "https://i.postimg.cc/bvtty6k2/alcohol.png",
            text: "Alcoholic Beverages / Liquid Items (All formats) Pornography Materials"
        },
        {
            imgSrc: "https://i.postimg.cc/c4P1xdmX/smoke.png",
            text: "Drugs/Narcotic Substances"
        },
        {
            imgSrc: "https://i.postimg.cc/fbNwd51d/vase.png",
            text: "Antiques (Without Proper Supporting Document)"
        },
        {
            imgSrc: "https://i.postimg.cc/FRFNWzrs/pet.png",
            text: "Live Animals"
        },
        {
            imgSrc: "https://i.postimg.cc/x8CjCChF/duel.png",
            text: "Explosives, Military Equipments, Firearms"
        },
        {
            imgSrc: "https://i.postimg.cc/0jbxv4KT/tobacco.png",
            text: "Tobacco & Tobacco Products"
        },
        {
            imgSrc: "https://i.postimg.cc/0NRNf9Kb/anatomy.png",
            text: "Human Corpses / Human Organs"
        }
    ];

    const dgiItems = [
        {
            imgSrc: "https://i.postimg.cc/kMpqs7kq/explosion.png",
            text: "Class 1: Explosives"
        },
        {
            imgSrc: "https://i.postimg.cc/HWqsHp33/gas-bottle.png",
            text: "Class 2: Gases"
        },
        {
            imgSrc: "https://i.postimg.cc/G2BdwNyZ/flammable.png",
            text: "Class 3: Flammable liquids"
        },
        {
            imgSrc: "https://i.postimg.cc/C5FB4Stj/currency.png",
            text: "Class 4: Flammable Solids"
        },
        {
            imgSrc: "https://i.postimg.cc/pXMx4sX6/nitrous-oxide.png",
            text: "Class 5: Oxidizers and Organic Peroxides"
        },
        {
            imgSrc: "https://i.postimg.cc/wT5HpD1T/no-chemical.png",
            text: "Class 6: Toxic & Infectious Substances"
        },
        {
            imgSrc: "https://i.postimg.cc/R0hzK8XN/nuclear.png",
            text: "Class 7:Radioactive material"
        },
        {
            imgSrc: "https://i.postimg.cc/PJssGqhw/corrosive.png",
            text: "Class 8: Corrosives"
        },
        {
            imgSrc: "https://i.postimg.cc/BQ39MtmW/magnet.png",
            text: "Class 9: Miscellaneous by law"
        }
    ];

    const renderItems = (items) => {
        return items.map((item, index) => (
            <Col key={index} md={6} lg={3} className='mt-4'>
                <div style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    textAlign: 'center',
                    margin: '0 5px',
                    minHeight: '200px',
                    border: "1px solid #DEDEDE",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    borderRadius: "10px"
                }}>
                    <center>
                        <span>
                            <img className='mt-3' style={{ width: "20%" }} src={item.imgSrc} alt="" />
                        </span>
                        <p className='mt-2' style={{ fontFamily: "'Outfit', sans-serif", color: 'black', fontSize: "18px", textAlign: 'center' }}>
                            {item.text}
                        </p>
                    </center>
                </div>
            </Col>
        ));
    };

    return (
        <div>
            <Header />
            <Container>
                <div style={{ overflow: "hidden" }}>
                    <section style={{ padding: "50px 0", backgroundColor: "#f8f9fa" }}>
                        <h6 className='mt-5' style={{ textAlign: "center", color: "#DE1818" }}>OURS POLICY</h6>
                        <h1 className='mt-2' style={{ textAlign: "center", fontFamily: "'Outfit', sans-serif" }}>We don't accept</h1>
                        <Row className='mt-4'>
                            {renderItems(items)}
                        </Row>
                        <center>
                            <p className='mt-3 text-center' style={{ color: "#DE1818", fontSize: "18px" }}>
                                Note: Commodities banned and restricted by law
                            </p>
                        </center>
                    </section>
                    <section style={{ padding: "50px 0" }}>
                        <h1 style={{ textAlign: "center", fontFamily: "'Outfit', sans-serif" }}>DGI-Dangerous Goods Items</h1>
                        <Row className='mt-4'>
                            {renderItems(dgiItems)}
                        </Row>
                        <center>
                            <p className='mt-3' style={{ color: "#DE1818", fontSize: "18px", textAlign: 'center' }}>
                                Definition: Commodities, which possess potentially hazardous characteristics. These are as laid out by IATA in the Dangerous Goods. The categories are listed above.
                            </p>
                        </center>
                    </section>
                </div>
            </Container>
        </div>
    );
}

export default Rules;
